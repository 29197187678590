const state = () => ({
  title: '',
  navItems: [
    { name: 'Общие настройки', to: { name: 'settings_general' }, ic: 'settings' },
  ],
  rubSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/RUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/RUB', ic: 'group_work' },
  ],
  rubSimSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/RUBSIM', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/RUBSIM', ic: 'group_work' },
  ],
  qiwiBtcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/QIWI_BTC', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/QIWI_BTC', ic: 'group_work' },
  ],
  usdtBtcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/USDT_BTC', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/USDT_BTC', ic: 'group_work' },
  ],
  rubUsdtSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/RUB_USDT', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/RUB_USDT', ic: 'group_work' },
  ],
  rubUsdSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/RUB_USD', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/RUB_USD', ic: 'group_work' },
  ],
  usdEurSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/USD_EUR', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/USD_EUR', ic: 'group_work' },
  ],
  usdEthSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/USD_ETH', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/USD_ETH', ic: 'group_work' },
  ],
  usdBtcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/USD_BTC', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/USD_BTC', ic: 'group_work' },
  ],
  ethBtcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/ETH_BTC', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/ETH_BTC', ic: 'group_work' },
  ],
  wmzBtcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WMZ_BTC', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WMZ_BTC', ic: 'group_work' },
  ],
  usdcBtcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/USDC_BTC', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/USDC_BTC', ic: 'group_work' },
  ],
  rubcashEthSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHRUB_ETH', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHRUB_ETH', ic: 'group_work' },
  ],
  rubcashWmzSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHRUB_WMZ', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHRUB_WMZ', ic: 'group_work' },
  ],
  rubcashUsdcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHRUB_USDC', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHRUB_USDC', ic: 'group_work' },
  ],
  usdtEthSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/USDT_ETH', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/USDT_ETH', ic: 'group_work' },
  ],
  usdtWmzSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/USDT_WMZ', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/USDT_WMZ', ic: 'group_work' },
  ],
  usdtUsdcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/USDT_USDC', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/USDT_USDC', ic: 'group_work' },
  ],
  wmzEthSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WMZ_ETH', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WMZ_ETH', ic: 'group_work' },
  ],
  rubBtcGxSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/RUB_BTC_GX', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/RUB_BTC_GX', ic: 'group_work' },
  ],
  sberRubXmrSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/XMR_SBERRUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/XMR_SBERRUB', ic: 'group_work' },
  ],
  tbRubXmrSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/XMR_TBRUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/XMR_TBRUB', ic: 'group_work' },
  ],
  accRubXmrSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/XMR_ACCRUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/XMR_ACCRUB', ic: 'group_work' },
  ],
  btcXmrSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/XMR_BTC', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/XMR_BTC', ic: 'group_work' },
  ],
  accRubBtcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/ACCRUB_BTC', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/ACCRUB_BTC', ic: 'group_work' },
  ],
  btcTrxSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/TRX_BTC', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/TRX_BTC', ic: 'group_work' },
  ],
  trxRubSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/TRX_SBERRUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/TRX_SBERRUB', ic: 'group_work' },
  ],
  trxXmrSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/TRX_XMR', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/TRX_XMR', ic: 'group_work' },
  ],
  trxSbpSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/TRX_SBPRUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/TRX_SBPRUB', ic: 'group_work' },
  ],
  trxAccRubSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/TRX_ACCRUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/TRX_ACCRUB', ic: 'group_work' },
  ],
  trxUsdcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/TRX_USDC', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/TRX_USDC', ic: 'group_work' },
  ],
  trxUsdtSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/TRX_USDTTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/TRX_USDTTRC20', ic: 'group_work' },
  ],
  usdUsdtSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CARDUSD_USDTTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CARDUSD_USDTTRC20', ic: 'group_work' },
  ],
  usdUsdcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CARDUSD_USDCTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CARDUSD_USDCTRC20', ic: 'group_work' },
  ],
  xmrUsdSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/XMR_CARDUSD', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/XMR_CARDUSD', ic: 'group_work' },
  ],
  trxUsdSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/TRX_CARDUSD', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/TRX_CARDUSD', ic: 'group_work' },
  ],
  usdcRubSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/USDCTRC20_SBERRUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/USDCTRC20_SBERRUB', ic: 'group_work' },
  ],
  xmrUsdtSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/XMR_USDTTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/XMR_USDTTRC20', ic: 'group_work' },
  ],
  xmrUsdcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/XMR_USDCTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/XMR_USDCTRC20', ic: 'group_work' },
  ],
  cnyRubSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CARDCNY_SBERRUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CARDCNY_SBERRUB', ic: 'group_work' },
  ],
  cnyAccRubSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CARDCNY_ACCRUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CARDCNY_ACCRUB', ic: 'group_work' },
  ],
  cnyBtcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CARDCNY_BTC', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CARDCNY_BTC', ic: 'group_work' },
  ],
  cnyUsdSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CARDCNY_CARDUSD', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CARDCNY_CARDUSD', ic: 'group_work' },
  ],
  cnyUsdtSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CARDCNY_USDTTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CARDCNY_USDTTRC20', ic: 'group_work' },
  ],
  cnyUsdcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CARDCNY_USDCTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CARDCNY_USDCTRC20', ic: 'group_work' },
  ],
  cnyXmrSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CARDCNY_XMR', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CARDCNY_XMR', ic: 'group_work' },
  ],
  cnyTrxSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CARDCNY_TRX', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CARDCNY_TRX', ic: 'group_work' },
  ],
  usdtAccRubSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/USDTTRC20_ACCRUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/USDTTRC20_ACCRUB', ic: 'group_work' },
  ],
  tusdRubSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/TUSDTRC20_SBERRUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/TUSDTRC20_SBERRUB', ic: 'group_work' },
  ],
  tusdAccRubSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/TUSDTRC20_ACCRUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/TUSDTRC20_ACCRUB', ic: 'group_work' },
  ],
  tusdBtcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/TUSDTRC20_BTC', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/TUSDTRC20_BTC', ic: 'group_work' },
  ],
  tusdUsdSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/TUSDTRC20_CARDUSD', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/TUSDTRC20_CARDUSD', ic: 'group_work' },
  ],
  tusdUsdtSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/TUSDTRC20_USDTTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/TUSDTRC20_USDTTRC20', ic: 'group_work' },
  ],
  tusdUsdcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/TUSDTRC20_USDCTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/TUSDTRC20_USDCTRC20', ic: 'group_work' },
  ],
  tusdXmrSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/TUSDTRC20_XMR', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/TUSDTRC20_XMR', ic: 'group_work' },
  ],
  tusdTrxSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/TUSDTRC20_TRX', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/TUSDTRC20_TRX', ic: 'group_work' },
  ],
  tusdCnySettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/TUSDTRC20_CARDCNY', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/TUSDTRC20_CARDCNY', ic: 'group_work' },
  ],
  alpCnyRubSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/ALPCNY_SBERRUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/ALPCNY_SBERRUB', ic: 'group_work' },
  ],
  alpCnyAccRubSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/ALPCNY_ACCRUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/ALPCNY_ACCRUB', ic: 'group_work' },
  ],
  alpCnyBtcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/ALPCNY_BTC', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/ALPCNY_BTC', ic: 'group_work' },
  ],
  alpCnyUsdSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/ALPCNY_CARDUSD', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/ALPCNY_CARDUSD', ic: 'group_work' },
  ],
  alpCnyUsdtSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/ALPCNY_USDTTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/ALPCNY_USDTTRC20', ic: 'group_work' },
  ],
  alpCnyUsdcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/ALPCNY_USDCTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/ALPCNY_USDCTRC20', ic: 'group_work' },
  ],
  alpCnyTusdSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/ALPCNY_TUSDTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/ALPCNY_TUSDTRC20', ic: 'group_work' },
  ],
  alpCnyXmrSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/ALPCNY_XMR', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/ALPCNY_XMR', ic: 'group_work' },
  ],
  alpCnyTrxSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/ALPCNY_TRX', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/ALPCNY_TRX', ic: 'group_work' },
  ],
  wctCnyRubSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WCTCNY_SBERRUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WCTCNY_SBERRUB', ic: 'group_work' },
  ],
  wctCnyAccRubSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WCTCNY_ACCRUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WCTCNY_ACCRUB', ic: 'group_work' },
  ],
  wctCnyBtcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WCTCNY_BTC', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WCTCNY_BTC', ic: 'group_work' },
  ],
  wctCnyUsdSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WCTCNY_CARDUSD', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WCTCNY_CARDUSD', ic: 'group_work' },
  ],
  wctCnyUsdtSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WCTCNY_USDTTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WCTCNY_USDTTRC20', ic: 'group_work' },
  ],
  wctCnyUsdcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WCTCNY_USDCTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WCTCNY_USDCTRC20', ic: 'group_work' },
  ],
  wctCnyTusdSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WCTCNY_TUSDTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WCTCNY_TUSDTRC20', ic: 'group_work' },
  ],
  wctCnyXmrSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WCTCNY_XMR', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WCTCNY_XMR', ic: 'group_work' },
  ],
  wctCnyTrxSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WCTCNY_TRX', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WCTCNY_TRX', ic: 'group_work' },
  ],
  wireCnyRubSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIRECNY_SBERRUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIRECNY_SBERRUB', ic: 'group_work' },
  ],
  wireCnyAccRubSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIRECNY_ACCRUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIRECNY_ACCRUB', ic: 'group_work' },
  ],
  wireCnyBtcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIRECNY_BTC', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIRECNY_BTC', ic: 'group_work' },
  ],
  wireCnyUsdSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIRECNY_CARDUSD', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIRECNY_CARDUSD', ic: 'group_work' },
  ],
  wireCnyUsdtSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIRECNY_USDTTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIRECNY_USDTTRC20', ic: 'group_work' },
  ],
  wireCnyUsdcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIRECNY_USDCTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIRECNY_USDCTRC20', ic: 'group_work' },
  ],
  wireCnyTusdSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIRECNY_TUSDTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIRECNY_TUSDTRC20', ic: 'group_work' },
  ],
  wireCnyXmrSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIRECNY_XMR', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIRECNY_XMR', ic: 'group_work' },
  ],
  wireCnyTrxSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIRECNY_TRX', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIRECNY_TRX', ic: 'group_work' },
  ],
  cashCnyRubSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHCNY_SBERRUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHCNY_SBERRUB', ic: 'group_work' },
  ],
  cashCnyAccRubSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHCNY_ACCRUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHCNY_ACCRUB', ic: 'group_work' },
  ],
  cashCnyBtcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHCNY_BTC', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHCNY_BTC', ic: 'group_work' },
  ],
  cashCnyUsdSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHCNY_CARDUSD', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHCNY_CARDUSD', ic: 'group_work' },
  ],
  cashCnyUsdtSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHCNY_USDTTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHCNY_USDTTRC20', ic: 'group_work' },
  ],
  cashCnyUsdcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHCNY_USDCTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHCNY_USDCTRC20', ic: 'group_work' },
  ],
  cashCnyTusdSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHCNY_TUSDTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHCNY_TUSDTRC20', ic: 'group_work' },
  ],
  cashCnyXmrSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHCNY_XMR', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHCNY_XMR', ic: 'group_work' },
  ],
  cashCnyTrxSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHCNY_TRX', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHCNY_TRX', ic: 'group_work' },
  ],
  cashUsdRubSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHUSD_SBERRUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHUSD_SBERRUB', ic: 'group_work' },
  ],
  cashUsdAccRubSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHUSD_ACCRUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHUSD_ACCRUB', ic: 'group_work' },
  ],
  cashUsdBtcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHUSD_BTC', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHUSD_BTC', ic: 'group_work' },
  ],
  cashUsdUsdSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHUSD_CARDUSD', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHUSD_CARDUSD', ic: 'group_work' },
  ],
  cashUsdUsdtSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHUSD_USDTTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHUSD_USDTTRC20', ic: 'group_work' },
  ],
  cashUsdUsdcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHUSD_USDCTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHUSD_USDCTRC20', ic: 'group_work' },
  ],
  cashUsdTusdSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHUSD_TUSDTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHUSD_TUSDTRC20', ic: 'group_work' },
  ],
  cashUsdXmrSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHUSD_XMR', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHUSD_XMR', ic: 'group_work' },
  ],
  cashUsdTrxSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHUSD_TRX', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHUSD_TRX', ic: 'group_work' },
  ],
  cashUsdCardCnySettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHUSD_CARDCNY', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHUSD_CARDCNY', ic: 'group_work' },
  ],
  cashUsdAlpCnySettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHUSD_ALPCNY', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHUSD_ALPCNY', ic: 'group_work' },
  ],
  cashUsdWctCnySettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHUSD_WCTCNY', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHUSD_WCTCNY', ic: 'group_work' },
  ],
  cashUsdWireCnySettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHUSD_WIRECNY', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHUSD_WIRECNY', ic: 'group_work' },
  ],
  cashUsdCashCnySettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHUSD_CASHCNY', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHUSD_CASHCNY', ic: 'group_work' },
  ],
  wireUsdRubSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIREUSD_SBERRUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIREUSD_SBERRUB', ic: 'group_work' },
  ],
  wireUsdAccRubSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIREUSD_ACCRUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIREUSD_ACCRUB', ic: 'group_work' },
  ],
  wireUsdBtcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIREUSD_BTC', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIREUSD_BTC', ic: 'group_work' },
  ],
  wireUsdUsdSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIREUSD_CARDUSD', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIREUSD_CARDUSD', ic: 'group_work' },
  ],
  wireUsdUsdtSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIREUSD_USDTTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIREUSD_USDTTRC20', ic: 'group_work' },
  ],
  wireUsdUsdcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIREUSD_USDCTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIREUSD_USDCTRC20', ic: 'group_work' },
  ],
  wireUsdTusdSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIREUSD_TUSDTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIREUSD_TUSDTRC20', ic: 'group_work' },
  ],
  wireUsdXmrSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIREUSD_XMR', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIREUSD_XMR', ic: 'group_work' },
  ],
  wireUsdTrxSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIREUSD_TRX', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIREUSD_TRX', ic: 'group_work' },
  ],
  wireUsdCardCnySettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIREUSD_CARDCNY', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIREUSD_CARDCNY', ic: 'group_work' },
  ],
  wireUsdAlpCnySettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIREUSD_ALPCNY', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIREUSD_ALPCNY', ic: 'group_work' },
  ],
  wireUsdWctCnySettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIREUSD_WCTCNY', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIREUSD_WCTCNY', ic: 'group_work' },
  ],
  wireUsdWireCnySettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIREUSD_WIRECNY', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIREUSD_WIRECNY', ic: 'group_work' },
  ],
  wireUsdCashCnySettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIREUSD_CASHCNY', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIREUSD_CASHCNY', ic: 'group_work' },
  ],
  cashRubRubSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHRUB_SBERRUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHRUB_SBERRUB', ic: 'group_work' },
  ],
  cashRubAccRubSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHRUB_ACCRUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHRUB_ACCRUB', ic: 'group_work' },
  ],
  cashRubBtcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHRUB_BTC', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHRUB_BTC', ic: 'group_work' },
  ],
  cashRubUsdSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHRUB_CARDUSD', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHRUB_CARDUSD', ic: 'group_work' },
  ],
  cashRubCashUsdSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHRUB_CASHUSD', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHRUB_CASHUSD', ic: 'group_work' },
  ],
  cashRubWireUsdSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHRUB_WIREUSD', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHRUB_WIREUSD', ic: 'group_work' },
  ],
  cashRubUsdtSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHRUB_USDTTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHRUB_USDTTRC20', ic: 'group_work' },
  ],
  cashRubUsdcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHRUB_USDCTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHRUB_USDCTRC20', ic: 'group_work' },
  ],
  cashRubTusdSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHRUB_TUSDTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHRUB_TUSDTRC20', ic: 'group_work' },
  ],
  cashRubXmrSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHRUB_XMR', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHRUB_XMR', ic: 'group_work' },
  ],
  cashRubTrxSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHRUB_TRX', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHRUB_TRX', ic: 'group_work' },
  ],
  cashRubCardCnySettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHRUB_CARDCNY', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHRUB_CARDCNY', ic: 'group_work' },
  ],
  cashRubAlpCnySettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHRUB_ALPCNY', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHRUB_ALPCNY', ic: 'group_work' },
  ],
  cashRubWctCnySettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHRUB_WCTCNY', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHRUB_WCTCNY', ic: 'group_work' },
  ],
  cashRubWireCnySettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHRUB_WIRECNY', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHRUB_WIRECNY', ic: 'group_work' },
  ],
  cashRubCashCnySettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/CASHRUB_CASHCNY', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/CASHRUB_CASHCNY', ic: 'group_work' },
  ],
  wireRubRubSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIRERUB_SBERRUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIRERUB_SBERRUB', ic: 'group_work' },
  ],
  wireRubAccRubSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIRERUB_ACCRUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIRERUB_ACCRUB', ic: 'group_work' },
  ],
  wireRubCashRubSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIRERUB_CASHRUB', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIRERUB_CASHRUB', ic: 'group_work' },
  ],
  wireRubBtcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIRERUB_BTC', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIRERUB_BTC', ic: 'group_work' },
  ],
  wireRubUsdSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIRERUB_CARDUSD', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIRERUB_CARDUSD', ic: 'group_work' },
  ],
  wireRubCashUsdSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIRERUB_CASHUSD', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIRERUB_CASHUSD', ic: 'group_work' },
  ],
  wireRubWireUsdSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIRERUB_WIREUSD', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIRERUB_WIREUSD', ic: 'group_work' },
  ],
  wireRubUsdtSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIRERUB_USDTTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIRERUB_USDTTRC20', ic: 'group_work' },
  ],
  wireRubUsdcSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIRERUB_USDCTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIRERUB_USDCTRC20', ic: 'group_work' },
  ],
  wireRubTusdSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIRERUB_TUSDTRC20', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIRERUB_TUSDTRC20', ic: 'group_work' },
  ],
  wireRubXmrSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIRERUB_XMR', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIRERUB_XMR', ic: 'group_work' },
  ],
  wireRubTrxSettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIRERUB_TRX', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIRERUB_TRX', ic: 'group_work' },
  ],
  wireRubCardCnySettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIRERUB_CARDCNY', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIRERUB_CARDCNY', ic: 'group_work' },
  ],
  wireRubAlpCnySettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIRERUB_ALPCNY', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIRERUB_ALPCNY', ic: 'group_work' },
  ],
  wireRubWctCnySettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIRERUB_WCTCNY', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIRERUB_WCTCNY', ic: 'group_work' },
  ],
  wireRubWireCnySettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIRERUB_WIRECNY', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIRERUB_WIRECNY', ic: 'group_work' },
  ],
  wireRubCashCnySettings: [
    { name: 'Настройки buy/sell', to: '/settings-course/WIRERUB_CASHCNY', ic: 'settings' },
    { name: 'Поставщики курсов', to: '/provider/WIRERUB_CASHCNY', ic: 'group_work' },
  ],
});

const mutations = {
  title: (state, val) => {
    state.title = val
  }
};

const actions = {
  setTitle ({ commit }, val) {
    commit('title', val)
  }
};

const getters = {
  title: state => state.title,
  navItems: state => state.navItems,
  rubSettings: state => state.rubSettings,
  rubSimSettings: state => state.rubSimSettings,
  qiwiBtcSettings: state => state.qiwiBtcSettings,
  usdtBtcSettings: state => state.usdtBtcSettings,
  rubUsdtSettings: state => state.rubUsdtSettings,
  rubUsdSettings: state => state.rubUsdSettings,
  usdEurSettings: state => state.usdEurSettings,
  usdEthSettings: state => state.usdEthSettings,
  usdBtcSettings: state => state.usdBtcSettings,
  ethBtcSettings: state => state.ethBtcSettings,
  wmzBtcSettings: state => state.wmzBtcSettings,
  usdcBtcSettings: state => state.usdcBtcSettings,
  rubcashEthSettings: state => state.rubcashEthSettings,
  rubcashWmzSettings: state => state.rubcashWmzSettings,
  rubcashUsdcSettings: state => state.rubcashUsdcSettings,
  usdtEthSettings: state => state.usdtEthSettings,
  usdtWmzSettings: state => state.usdtWmzSettings,
  usdtUsdcSettings: state => state.usdtUsdcSettings,
  wmzEthSettings: state => state.wmzEthSettings,
  rubBtcGxSettings: state => state.rubBtcGxSettings,
  sberRubXmrSettings: state => state.sberRubXmrSettings,
  tbRubXmrSettings: state => state.tbRubXmrSettings,
  accRubXmrSettings: state => state.accRubXmrSettings,
  btcXmrSettings: state => state.btcXmrSettings,
  accRubBtcSettings: state => state.accRubBtcSettings,
  btcTrxSettings: state => state.btcTrxSettings,
  trxRubSettings: state => state.trxRubSettings,
  trxXmrSettings: state => state.trxXmrSettings,
  trxSbpSettings: state => state.trxSbpSettings,
  trxAccRubSettings: state => state.trxAccRubSettings,
  trxUsdcSettings: state => state.trxUsdcSettings,
  trxUsdtSettings: state => state.trxUsdtSettings,
  usdUsdtSettings: state => state.usdUsdtSettings,
  usdUsdcSettings: state => state.usdUsdcSettings,
  xmrUsdSettings: state => state.xmrUsdSettings,
  trxUsdSettings: state => state.trxUsdSettings,
  usdcRubSettings: state => state.usdcRubSettings,
  xmrUsdtSettings: state => state.xmrUsdtSettings,
  xmrUsdcSettings: state => state.xmrUsdcSettings,
  cnyRubSettings: state => state.cnyRubSettings,
  cnyAccRubSettings: state => state.cnyAccRubSettings,
  cnyBtcSettings: state => state.cnyBtcSettings,
  cnyUsdSettings: state => state.cnyUsdSettings,
  cnyUsdtSettings: state => state.cnyUsdtSettings,
  cnyUsdcSettings: state => state.cnyUsdcSettings,
  cnyXmrSettings: state => state.cnyXmrSettings,
  cnyTrxSettings: state => state.cnyTrxSettings,
  usdtAccRubSettings: state => state.usdtAccRubSettings,
  tusdRubSettings: state => state.tusdRubSettings,
  tusdAccRubSettings: state => state.tusdAccRubSettings,
  tusdBtcSettings: state => state.tusdBtcSettings,
  tusdUsdSettings: state => state.tusdUsdSettings,
  tusdUsdtSettings: state => state.tusdUsdtSettings,
  tusdUsdcSettings: state => state.tusdUsdcSettings,
  tusdXmrSettings: state => state.tusdXmrSettings,
  tusdTrxSettings: state => state.tusdTrxSettings,
  tusdCnySettings: state => state.tusdCnySettings,
  alpCnyRubSettings: state => state.alpCnyRubSettings,
  alpCnyAccRubSettings: state => state.alpCnyAccRubSettings,
  alpCnyBtcSettings: state => state.alpCnyBtcSettings,
  alpCnyUsdSettings: state => state.alpCnyUsdSettings,
  alpCnyUsdtSettings: state => state.alpCnyUsdtSettings,
  alpCnyUsdcSettings: state => state.alpCnyUsdcSettings,
  alpCnyTusdSettings: state => state.alpCnyTusdSettings,
  alpCnyXmrSettings: state => state.alpCnyXmrSettings,
  alpCnyTrxSettings: state => state.alpCnyTrxSettings,
  wctCnyRubSettings: state => state.wctCnyRubSettings,
  wctCnyAccRubSettings: state => state.wctCnyAccRubSettings,
  wctCnyBtcSettings: state => state.wctCnyBtcSettings,
  wctCnyUsdSettings: state => state.wctCnyUsdSettings,
  wctCnyUsdtSettings: state => state.wctCnyUsdtSettings,
  wctCnyUsdcSettings: state => state.wctCnyUsdcSettings,
  wctCnyTusdSettings: state => state.wctCnyTusdSettings,
  wctCnyXmrSettings: state => state.wctCnyXmrSettings,
  wctCnyTrxSettings: state => state.wctCnyTrxSettings,
  wireCnyRubSettings: state => state.wireCnyRubSettings,
  wireCnyAccRubSettings: state => state.wireCnyAccRubSettings,
  wireCnyBtcSettings: state => state.wireCnyBtcSettings,
  wireCnyUsdSettings: state => state.wireCnyUsdSettings,
  wireCnyUsdtSettings: state => state.wireCnyUsdtSettings,
  wireCnyUsdcSettings: state => state.wireCnyUsdcSettings,
  wireCnyTusdSettings: state => state.wireCnyTusdSettings,
  wireCnyXmrSettings: state => state.wireCnyXmrSettings,
  wireCnyTrxSettings: state => state.wireCnyTrxSettings,
  cashCnyRubSettings: state => state.cashCnyRubSettings,
  cashCnyAccRubSettings: state => state.cashCnyAccRubSettings,
  cashCnyBtcSettings: state => state.cashCnyBtcSettings,
  cashCnyUsdSettings: state => state.cashCnyUsdSettings,
  cashCnyUsdtSettings: state => state.cashCnyUsdtSettings,
  cashCnyUsdcSettings: state => state.cashCnyUsdcSettings,
  cashCnyTusdSettings: state => state.cashCnyTusdSettings,
  cashCnyXmrSettings: state => state.cashCnyXmrSettings,
  cashCnyTrxSettings: state => state.cashCnyTrxSettings,
  cashUsdRubSettings: state => state.cashUsdRubSettings,
  cashUsdAccRubSettings: state => state.cashUsdAccRubSettings,
  cashUsdBtcSettings: state => state.cashUsdBtcSettings,
  cashUsdUsdSettings: state => state.cashUsdUsdSettings,
  cashUsdUsdtSettings: state => state.cashUsdUsdtSettings,
  cashUsdUsdcSettings: state => state.cashUsdUsdcSettings,
  cashUsdTusdSettings: state => state.cashUsdTusdSettings,
  cashUsdXmrSettings: state => state.cashUsdXmrSettings,
  cashUsdTrxSettings: state => state.cashUsdTrxSettings,
  cashUsdCardCnySettings: state => state.cashUsdCardCnySettings,
  cashUsdAlpCnySettings: state => state.cashUsdAlpCnySettings,
  cashUsdWctCnySettings: state => state.cashUsdWctCnySettings,
  cashUsdWireCnySettings: state => state.cashUsdWireCnySettings,
  cashUsdCashCnySettings: state => state.cashUsdCashCnySettings,
  wireUsdRubSettings: state => state.wireUsdRubSettings,
  wireUsdAccRubSettings: state => state.wireUsdAccRubSettings,
  wireUsdBtcSettings: state => state.wireUsdBtcSettings,
  wireUsdUsdSettings: state => state.wireUsdUsdSettings,
  wireUsdUsdtSettings: state => state.wireUsdUsdtSettings,
  wireUsdUsdcSettings: state => state.wireUsdUsdcSettings,
  wireUsdTusdSettings: state => state.wireUsdTusdSettings,
  wireUsdXmrSettings: state => state.wireUsdXmrSettings,
  wireUsdTrxSettings: state => state.wireUsdTrxSettings,
  wireUsdCardCnySettings: state => state.wireUsdCardCnySettings,
  wireUsdAlpCnySettings: state => state.wireUsdAlpCnySettings,
  wireUsdWctCnySettings: state => state.wireUsdWctCnySettings,
  wireUsdWireCnySettings: state => state.wireUsdWireCnySettings,
  wireUsdCashCnySettings: state => state.wireUsdCashCnySettings,
  cashRubRubSettings: state => state.cashRubRubSettings,
  cashRubAccRubSettings: state => state.cashRubAccRubSettings,
  cashRubBtcSettings: state => state.cashRubBtcSettings,
  cashRubUsdSettings: state => state.cashRubUsdSettings,
  cashRubCashUsdSettings: state => state.cashRubCashUsdSettings,
  cashRubWireUsdSettings: state => state.cashRubWireUsdSettings,
  cashRubUsdtSettings: state => state.cashRubUsdtSettings,
  cashRubUsdcSettings: state => state.cashRubUsdcSettings,
  cashRubTusdSettings: state => state.cashRubTusdSettings,
  cashRubXmrSettings: state => state.cashRubXmrSettings,
  cashRubTrxSettings: state => state.cashRubTrxSettings,
  cashRubCardCnySettings: state => state.cashRubCardCnySettings,
  cashRubAlpCnySettings: state => state.cashRubAlpCnySettings,
  cashRubWctCnySettings: state => state.cashRubWctCnySettings,
  cashRubWireCnySettings: state => state.cashRubWireCnySettings,
  cashRubCashCnySettings: state => state.cashRubCashCnySettings,
  wireRubRubSettings: state => state.wireRubRubSettings,
  wireRubAccRubSettings: state => state.wireRubAccRubSettings,
  wireRubCashRubSettings: state => state.wireRubCashRubSettings,
  wireRubBtcSettings: state => state.wireRubBtcSettings,
  wireRubUsdSettings: state => state.wireRubUsdSettings,
  wireRubCashUsdSettings: state => state.wireRubCashUsdSettings,
  wireRubWireUsdSettings: state => state.wireRubWireUsdSettings,
  wireRubUsdtSettings: state => state.wireRubUsdtSettings,
  wireRubUsdcSettings: state => state.wireRubUsdcSettings,
  wireRubTusdSettings: state => state.wireRubTusdSettings,
  wireRubXmrSettings: state => state.wireRubXmrSettings,
  wireRubTrxSettings: state => state.wireRubTrxSettings,
  wireRubCardCnySettings: state => state.wireRubCardCnySettings,
  wireRubAlpCnySettings: state => state.wireRubAlpCnySettings,
  wireRubWctCnySettings: state => state.wireRubWctCnySettings,
  wireRubWireCnySettings: state => state.wireRubWireCnySettings,
  wireRubCashCnySettings: state => state.wireRubCashCnySettings,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
